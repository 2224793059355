* {
  box-sizing: border-box;
  font-family: "Noto Sans Thai", sans-serif;
}

.App {
  background-color: #FFFFE0;
  background-image: url("../public/images/background/muvmuv-bg.png");
  background-attachment: fixed;
  background-size: contain;
}

.bg-MLColor1 {
  background-image: linear-gradient(to bottom right,#49ffc5,#ff4fdf )
}

.bg-MLColor2 {
  background-image: linear-gradient(to top right,#49ffc5,#ff4fdf )
}

.bg-MLColor3 {
  background-image: linear-gradient(to bottom,#7FFFD4,#FFC0CB )
}

.bg-MLColor4 {
  background-image: linear-gradient(to top,#7FFFD4,#FFC0CB )
}

.bg-MLColor5 {
  background-image: linear-gradient(to right, rgb(127, 255, 212, 0.9),rgb(255, 192, 203, 0.9) );
}

.bg-MLColor6 {
  background-image: linear-gradient(to left,#7FFFD4,#FFC0CB )
}

.bg-MColor {
  background-color: #7FFFD4
}

.bg-LColor {
  background-color: #FFC0CB
}

.card-content {
  /* background-color: rgb(255, 232, 206); */
  border: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  /* margin-left: 5vw;
  margin-right: 5vw; */
}

.text-shadow {
  text-shadow: 2px 2px 4px black;
}

.text-history {
  font-size: 13.3px;
  margin-top: 13px;
}

.dropdown-menu.show {
  --bs-dropdown-link-active-color: #1e2125;
  --bs-dropdown-link-active-bg: #e9ecef;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
